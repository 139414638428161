import { useEffect } from "react";
import { batch, shallowEqual, useSelector } from "react-redux";
import { emitMakeBet } from "@analytics/emitMakeBet";
import {
  EmitUnlockPremiumMessageParams,
  MessageStatusResult,
} from "src/analytics/messages/exports/types";
import {
  emitUnlockPremiumMessageAndSendGift,
  unlockPremiumMessage,
} from "src/features/chat/premiumMessage/state/asyncAction";
import afterPurchaseTaskManagerActions from "state/actionCreators/afterPurchaseTaskManager";
import subscriptionsActions from "state/actionCreators/subscriptionPerStreamerList";
import processLotBid from "state/flows/nft/processLotBid";
import { itemsForSendAfterPurchase, userSelectors } from "state/selectors";
import usePrevious from "ui/hooks/usePrevious";
import { useAppDispatch } from "./useAppDispatch";

export enum AFTER_BUY_OPERATIONS {
  AUCTION_BID = "AUCTION_BID",
  RENEW_SUBSCRIPTION = "RENEW_SUBSCRIPTION",
  SUBSCRIPTION = "SUBSCRIPTION",
  SUBSCRIPTION_UPGRADE = "SUBSCRIPTION_UPGRADE",
  UNLOCK_PREMIUM_MESSAGE = "UNLOCK_PREMIUM_MESSAGE",
}

const useAfterPurchaseTackManager = () => {
  const dispatch = useAppDispatch();
  const itemForSend = useSelector(
    itemsForSendAfterPurchase.getItemForSend,
    shallowEqual
  );
  const coinsBalance = useSelector(userSelectors.getCoinsBalance);
  const myAccountId = useSelector(userSelectors.getMyAccountId);
  const previousCoinsBalance = usePrevious(coinsBalance);

  useEffect(() => {
    switch (itemForSend?.type) {
      case AFTER_BUY_OPERATIONS.AUCTION_BID:
        if (itemForSend && coinsBalance > (previousCoinsBalance || 0)) {
          const {
            auctionId,
            screenName,
            source,
            screenState,
            target,
            min,
            lotId,
            amount,
          } = itemForSend;

          batch(() => {
            dispatch(afterPurchaseTaskManagerActions.processFirstQueueItem());
            emitMakeBet({
              target,
              screenName,
              source,
              screenState,
              itemId: lotId,
              itemType: "nft_card",
              increaseAmount: amount - min,
              totalAmount: amount,
              balance: coinsBalance,
            });
            dispatch(
              processLotBid({
                lotId,
                amount,
                auctionId,
                target,
                onBidEnd: undefined,
              })
            );
          });
        }
        break;
      case AFTER_BUY_OPERATIONS.SUBSCRIPTION:
        if (itemForSend && coinsBalance > (previousCoinsBalance || 0)) {
          batch(() => {
            dispatch(afterPurchaseTaskManagerActions.processFirstQueueItem());
            dispatch(
              subscriptionsActions.createSubscriptionWithCredits({
                accountId: itemForSend.streamerId,
                marketOfferId: itemForSend.offer.marketOfferId,
                triggerId: itemForSend?.triggerId,
                onSuccess: ({ streamerId, subscription }) => {
                  dispatch(
                    subscriptionsActions.subscribeStreamer({
                      streamerId,
                      subscription,
                    })
                  );
                },
              })
            );
          });
        }
        break;
      case AFTER_BUY_OPERATIONS.SUBSCRIPTION_UPGRADE:
        if (itemForSend && coinsBalance > previousCoinsBalance) {
          const { subscriptionId, details, offer, streamerId, triggerId } =
            itemForSend;

          batch(() => {
            dispatch(
              subscriptionsActions.upgradeSubscription({
                streamerId,
                subscriptionId,
                details,
                externalOfferId: offer.marketOfferId,
                ...(triggerId && { triggerId }),
                onSuccess: ({ streamerId, subscription }) => {
                  dispatch(
                    subscriptionsActions.subscribeStreamer({
                      streamerId,
                      subscription,
                    })
                  );
                },
              })
            );
            dispatch(afterPurchaseTaskManagerActions.processFirstQueueItem());
          });
        }
        break;
      case AFTER_BUY_OPERATIONS.RENEW_SUBSCRIPTION:
        if (itemForSend && coinsBalance > (previousCoinsBalance || 0)) {
          batch(() => {
            dispatch(afterPurchaseTaskManagerActions.processFirstQueueItem());
            dispatch(
              subscriptionsActions.renewSubscription({
                subscriptionId: itemForSend.subscriptionId,
                isSubWithCredits: itemForSend.isSubWithCredits,
                onSubscriptionExpired: () => {
                  dispatch(
                    subscriptionsActions.clearSubscription({
                      accountId: itemForSend.streamerId,
                    })
                  );
                },
                onSuccess: ({ subscription }) => {
                  dispatch(
                    subscriptionsActions.subscribeStreamer({
                      streamerId: itemForSend.streamerId,
                      subscription,
                    })
                  );
                },
              })
            );
          });
        }
        break;
      case AFTER_BUY_OPERATIONS.UNLOCK_PREMIUM_MESSAGE:
        if (itemForSend && coinsBalance > (previousCoinsBalance || 0)) {
          const {
            messageId,
            contentType,
            conversationId,
            giftId,
            recipientId,
          } = itemForSend;

          const emitParams: Omit<EmitUnlockPremiumMessageParams, "result"> = {
            accountId: myAccountId,
            chatId: conversationId,
            giftId,
            messageId,
            recipientAccountId: recipientId,
            type: contentType,
          };

          batch(() => {
            dispatch(afterPurchaseTaskManagerActions.processFirstQueueItem());

            dispatch(
              unlockPremiumMessage({
                messageId,
              })
            )
              .unwrap()
              .then(() => {
                dispatch(
                  emitUnlockPremiumMessageAndSendGift(
                    {
                      ...emitParams,
                      result: MessageStatusResult.SUCCESS,
                    },
                    true
                  )
                );
              })
              .catch(() => {
                dispatch(
                  emitUnlockPremiumMessageAndSendGift(
                    {
                      ...emitParams,
                      result: MessageStatusResult.FAILURE,
                    },
                    true
                  )
                );
              });
          });
        }
        break;
      default:
        break;
    }
  }, [dispatch, myAccountId, coinsBalance, previousCoinsBalance, itemForSend]);
};

export default useAfterPurchaseTackManager;
