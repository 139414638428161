import { getAxonPixelId } from "environment";
export default () => {
  if (!getAxonPixelId()) {
    return;
  }

  !(function (e, r) {
    const t = [
      "https://s.axon.ai/pixel.js",
      "https://c.albss.com/p/l/loader.iife.js",
    ];
    if (!e.axon) {
      // eslint-disable-next-line no-multi-assign,no-var
      var a = (e.axon = function () {
        a.performOperation
          ? // eslint-disable-next-line prefer-rest-params,prefer-spread
            a.performOperation.apply(a, arguments)
          : // eslint-disable-next-line prefer-rest-params
            a.operationQueue.push(arguments);
      });
      // eslint-disable-next-line no-sequences
      (a.operationQueue = []),
        (a.ts = Date.now()),
        (a.eventKey = getAxonPixelId());
      for (
        let n = r.getElementsByTagName("script")[0], o = 0;
        o < t.length;
        o++
      ) {
        const i = r.createElement("script");
        // eslint-disable-next-line no-sequences
        (i.async = !0), (i.src = t[o]), n.parentNode.insertBefore(i, n);
      }
    }
  })(window, document);

  window?.axon?.("init");
};
